import { PaymentMethods } from "@jackfruit/common"
import { useOrderSummary } from "~/hooks/useOrderSummary"
import { usePageCartClientPrintService } from "~/hooks/usePageCartClientPrintService"
export const PAYMENT_METHOD_KEY = "payment.method"

export const usePaymentOption = () => {
  const data = usePageCartClientPrintService()

  if (data.clientPrintService?.paymentConfig === undefined) {
    throw new Error(
      "no payment config configured on client print service, please check server configuration"
    )
  }

  const {
    clientPrintService: { paymentConfig },
    pageCart: { couponCodeStatus },
  } = data

  const {
    orderSummary: {
      data: { totalFloat },
    },
  } = useOrderSummary()

  const couponAccepted = couponCodeStatus === "accepted"
  const isFree = totalFloat === 0 && couponAccepted

  const options = Object.keys(paymentConfig) as PaymentMethods[]
  const hasStripe = options[0] === "stripe"
  const hasPayInStore = options[0] === "payInStore"

  return { option: options[0], hasPayInStore, hasStripe, isFree }
}
