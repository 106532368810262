import { Box } from "@chakra-ui/react"
import React from "react"
import { OrderSummaryEntity } from "~/interfaces/entities/OrderSummary"
import CouponCodeInputMcDonald from "../fields/CouponCodeInputMcDonald"
import FormHeading from "../FormHeading"
import OrderSummaryLineItems from "./OrderSummaryLineItems"
import OrderSummaryTotals from "./OrderSummaryTotals"

interface Props {
  order: OrderSummaryEntity
  headingText?: string
}

const OrderSummaryMcDonald: React.FC<Props> = ({ order, headingText }) => {
  return (
    <Box>
      {headingText && <FormHeading>{headingText}</FormHeading>}
      <OrderSummaryLineItems order={order} />
      <OrderSummaryTotals
        isPendingAddress={order.isPendingAddress}
        freightFloat={order.data.freightFloat}
        freightHuman={order.data.freightHuman}
        taxInclusive={order.data.taxInclusive}
        taxTotalFloat={order.data.taxTotalFloat}
        taxTotalHuman={order.data.taxTotalHuman}
        totalHuman={order.data.totalHuman}
        couponCode={order.data.couponCode}
        printServiceId={order.data.printService}
        discountFloat={order.data.discountFloat}
        discountHuman={order.data.discountHuman}
        isUpdating={order.isUpdating}
      />
      <CouponCodeInputMcDonald mt={4} />
    </Box>
  )
}

export default OrderSummaryMcDonald
