import { Box, Heading } from "@chakra-ui/react"
import React from "react"
import { OrderSummaryEntity } from "~/interfaces/entities/OrderSummary"
import CouponCodeInput from "../fields/CouponCodeInput"
import FormHeading from "../FormHeading"
import OrderSummaryLineItems from "./OrderSummaryLineItems"
import OrderSummaryTotals from "./OrderSummaryTotals"
import { ReactComponent as OrderAddressPromptSVG } from "~/images/OrderAddressPrompt.svg"
import { useTranslation } from "react-i18next"

interface Props {
  order: OrderSummaryEntity
  headingText?: string
}

const OrderSummaryAddressRequired: React.FC<Props> = ({
  order,
  headingText,
}) => {
  const { t } = useTranslation()

  return order.isPendingAddress ? (
    <Box px={4} py={4} mt={[0, 36]} textAlign="center">
      <OrderAddressPromptSVG />
      <Heading
        as="h5"
        fontSize={["md", "lg"]}
        lineHeight={["base", "normal"]}
        mt={4}
        mb={0}
        color="gray.400"
      >
        {t("components.checkout.OrderSummary.OrderAddressPrompt")}
      </Heading>
    </Box>
  ) : (
    <Box>
      {headingText && <FormHeading>{headingText}</FormHeading>}
      <OrderSummaryLineItems order={order} />
      <OrderSummaryTotals
        isPendingAddress={order.isPendingAddress}
        freightFloat={order.data.freightFloat}
        freightHuman={order.data.freightHuman}
        taxInclusive={order.data.taxInclusive}
        taxTotalFloat={order.data.taxTotalFloat}
        taxTotalHuman={order.data.taxTotalHuman}
        totalHuman={order.data.totalHuman}
        couponCode={order.data.couponCode}
        printServiceId={order.data.printService}
        discountFloat={order.data.discountFloat}
        discountHuman={order.data.discountHuman}
        isUpdating={order.isUpdating}
      />
      <CouponCodeInput mt={4} />
    </Box>
  )
}

export default OrderSummaryAddressRequired
