import { Box, Flex, Heading, Stack } from "@chakra-ui/react"
import {
  BlockCheckoutEntityHydrated,
  PageEntityHydrated,
} from "@jackfruit/common"
import { debounce, uniqBy } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "react-use"
import { useAllPageUploadsAreReady } from "~/hooks/useAllPageUploadsAreReady"
import { useAuth } from "~/hooks/useAuth"
import { useCart } from "~/hooks/useCart"
import { useDefaultClient } from "~/hooks/useDefaultClient"
import { useLocalStorageKeys } from "~/hooks/useLocalStorageKeys"
import { useOrderSummary } from "~/hooks/useOrderSummary"
import { usePageCartClientPrintService } from "~/hooks/usePageCartClientPrintService"
import { usePageTerritories } from "~/hooks/usePageTerritories"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useStore } from "~/hooks/useStore"
import { Address } from "~/interfaces/entities/Address"
import { User } from "~/interfaces/entities/User"
import {
  generatePathForPage,
  listOfStatesByCountryCode,
} from "~/services/Utils"
import DeliveryForm, { DeliveryFormFields } from "../checkout/DeliveryForm"
import OrderSummary from "../checkout/order-summary/OrderSummary"
import PickupForm, { PickupFormFields } from "../checkout/PickupForm"
import ErrorPanel from "../ErrorPanel"
import Wrapper from "../Wrapper"

export interface CheckoutFormContextInterface {
  initialiseFieldsList: (fieldList: string[]) => void
  fieldsList: string[]
}

export const CheckoutFormContext =
  React.createContext<CheckoutFormContextInterface>(
    {} as CheckoutFormContextInterface
  )

export interface Props {
  config: BlockCheckoutEntityHydrated
  pages: PageEntityHydrated[]
}

const CheckoutBlock: React.FC<Props> = ({ config, pages }) => {
  const { t } = useTranslation()
  const process = useProcessActions()
  const { state: authState } = useAuth()
  const { cart, cartActions } = useCart()
  const { store } = useStore(cart.storeId)
  const { orderSummary } = useOrderSummary()
  const { client } = useDefaultClient()
  const { clientPrintService } = usePageCartClientPrintService()
  const { localStorageAddressKey, localStorageUserKey } = useLocalStorageKeys()
  const [storedAddress] = useLocalStorage<Address>(localStorageAddressKey)
  const [storedUser] = useLocalStorage<User>(localStorageUserKey)

  const {
    defaultDeliveryTerritory,
    defaultPickupTerritory,
    deliveryTerritories,
    pickupTerritories,
  } = usePageTerritories()

  const { supportEmail: clientSupportEmail } = client ?? {}
  const { supportEmail: printServiceSupportEmail } = clientPrintService ?? {}
  const supportEmailToUse =
    printServiceSupportEmail ?? clientSupportEmail ?? null
  const { fulfillment, couponCodeStatus, printServiceId } = cart

  const {
    isUpdating: orderIsUpdating,
    isPendingAddress: orderIsPendingAddress,
    isProcessing: orderIsProcessing,
    hasProcessingError: orderHasError,
    processingError: orderError,
    error: dryError,
    hasError: dryOrderHasError,
  } = orderSummary

  const orderErrorArr = [orderError, dryError]
  const orderErrors = uniqBy(orderErrorArr, "message").filter(error =>
    Boolean(error?.message)
  )

  const isOrderNeedToPay =
    orderSummary.data.totalFloat !== 0 || couponCodeStatus !== "accepted"

  const [fieldsList, setFieldsList] = useState<string[]>([])

  useEffect(() => {
    if (window.debugJson) {
      window.debugJson.printServiceId = printServiceId
    }
  }, [printServiceId])

  const initialiseFieldsList = useCallback(
    (fieldsList: string[]) => {
      setFieldsList(fieldsList)
    },
    [setFieldsList]
  )

  const allUploadsAreReady = useAllPageUploadsAreReady()
  const updateUserAndAddress = useCallback(
    (formData: PickupFormFields | DeliveryFormFields) => {
      const {
        email,
        fullName,
        firstName,
        lastName,
        phoneNumber = "",
        line1,
        line2,
        city,
        state,
        country,
        postcode,
        payment,
      } = formData as DeliveryFormFields & PickupFormFields

      // update user data
      cartActions.setUser({
        email,
        fullName,
        firstName,
        lastName,
        phoneNumber: phoneNumber.replace(/[^0-9+]/g, ""),
      })

      // update address data
      cartActions.setAddress({
        name: fullName,
        state,
        line1,
        line2,
        city,
        country,
        postcode,
      })

      // Update payment
      if (payment) {
        const { method } = payment
        cartActions.setPayment({
          type: method,
          data: payment.data?.[method] || null,
        })
      }
    },
    [cartActions]
  )

  const orderSuccessPickupPath = useMemo(
    () =>
      config.orderSuccessPickupPage
        ? generatePathForPage(config.orderSuccessPickupPage, pages)
        : "",
    [config.orderSuccessPickupPage, pages]
  )

  const orderSuccessDeliveryPath = useMemo(
    () =>
      config.orderSuccessDeliveryPage
        ? generatePathForPage(config.orderSuccessDeliveryPage, pages)
        : "",
    [config.orderSuccessDeliveryPage, pages]
  )

  const onPickupSubmit = useCallback(
    (formData: PickupFormFields) => {
      updateUserAndAddress(formData)
      const { payment } = formData

      if (payment?.method === "stripe" && isOrderNeedToPay) {
        process.createOrder({ successUrl: orderSuccessPickupPath })
      } else {
        //If the payment method is not stripe or the order is free,
        //we will place an order straight away
        process.placeOrder({
          successUrl: orderSuccessPickupPath,
        })
      }
    },
    [updateUserAndAddress, isOrderNeedToPay, process, orderSuccessPickupPath]
  )

  const onDeliverySubmit = useCallback(
    (formData: DeliveryFormFields) => {
      updateUserAndAddress(formData)
      const { payment } = formData

      if (payment?.method === "stripe" && isOrderNeedToPay) {
        process.createOrder({ successUrl: orderSuccessDeliveryPath })
      } else {
        //If the payment method is not stripe or the order is free,
        //we will place an order straight away
        process.placeOrder({
          successUrl: orderSuccessDeliveryPath,
        })
      }
    },
    [updateUserAndAddress, isOrderNeedToPay, process, orderSuccessDeliveryPath]
  )

  const onUserDataChange = debounce(
    (formData: PickupFormFields | DeliveryFormFields) => {
      updateUserAndAddress(formData)
    },
    600
  )

  const defaultPickupValues = useMemo(() => {
    let country = storedAddress?.country
    if (storedAddress?.country) {
      const territory = pickupTerritories.find(
        t => t.code === storedAddress?.country
      )
      if (!territory) {
        country = undefined
      }
    }

    return {
      fullName: authState.name ?? storedUser?.fullName,
      email: authState.emailAddress ?? storedUser?.email,
      phoneNumber: authState.phoneNumber ?? storedUser?.phoneNumber,
      country: country ?? defaultPickupTerritory,
    }
  }, [
    authState.emailAddress,
    authState.name,
    authState.phoneNumber,
    defaultPickupTerritory,
    pickupTerritories,
    storedAddress?.country,
    storedUser?.email,
    storedUser?.fullName,
    storedUser?.phoneNumber,
  ])

  const defaultDeliveryValues = useMemo(() => {
    let country = storedAddress?.country
    if (storedAddress?.country) {
      const territory = deliveryTerritories.find(
        t => t.code === storedAddress?.country
      )
      if (!territory) {
        country = undefined
      }
    }

    country = country ?? defaultDeliveryTerritory

    const stateCodes = listOfStatesByCountryCode(country)
    let state = storedAddress?.state
    if (!Boolean(state) && stateCodes.length > 0) {
      state = stateCodes[0].state_code
    }

    return {
      fullName: authState.name ? authState.name : storedUser?.fullName,
      email: authState.emailAddress
        ? authState.emailAddress
        : storedUser?.email,
      phoneNumber: authState.phoneNumber
        ? authState.phoneNumber
        : storedUser?.phoneNumber,
      country,
      line1: storedAddress?.line1,
      line2: storedAddress?.line2,
      city: storedAddress?.city,
      postcode: storedAddress?.postcode,
      state,
    }
  }, [
    authState.emailAddress,
    authState.name,
    authState.phoneNumber,
    storedUser?.email,
    storedUser?.fullName,
    storedUser?.phoneNumber,
    defaultDeliveryTerritory,
    deliveryTerritories,
    storedAddress?.city,
    storedAddress?.country,
    storedAddress?.line1,
    storedAddress?.line2,
    storedAddress?.postcode,
    storedAddress?.state,
  ])

  const checkoutForm =
    fulfillment === "pickup" ? (
      <PickupForm
        defaultValues={defaultPickupValues}
        onSubmit={onPickupSubmit}
        onChange={onUserDataChange}
        isLoading={!!orderIsProcessing}
        isDisabled={!!orderIsUpdating}
        isReady={allUploadsAreReady}
        orderSuccessPath={orderSuccessPickupPath}
      />
    ) : (
      <DeliveryForm
        defaultValues={defaultDeliveryValues}
        onSubmit={onDeliverySubmit}
        onChange={onUserDataChange}
        isLoading={!!orderIsProcessing}
        isDisabled={!!orderIsUpdating}
        isReady={allUploadsAreReady}
        orderSuccessPath={orderSuccessDeliveryPath}
      />
    )

  const baseError = !orderIsPendingAddress && orderHasError && dryOrderHasError
  const showErrorWithEmail = baseError && supportEmailToUse
  const showErrorWithoutEmail = baseError && !supportEmailToUse

  let errorMessage = ""
  if (showErrorWithEmail) {
    errorMessage = t("components.blocks.CheckoutBlock.ServerError", {
      email: supportEmailToUse,
    })
  } else if (showErrorWithoutEmail) {
    errorMessage = t("components.blocks.CheckoutBlock.ServerErrorNoEmail")
  }

  return (
    <Wrapper
      id="p-customer" // GTM: checkout visible
      py={16}
      hasSeparator={true}
    >
      <Heading as="h3" fontSize="2xl" mb={8} textTransform="capitalize">
        {t("components.blocks.CheckoutBlock.Heading")}
      </Heading>

      <Stack direction={{ base: "column-reverse", md: "row" }} spacing={6}>
        <Flex flex={1} direction="column">
          {orderErrors.map((error, index) => (
            <ErrorPanel key={index} serverError={error} error={errorMessage} />
          ))}

          <CheckoutFormContext.Provider
            value={{
              initialiseFieldsList,
              fieldsList,
            }}
          >
            {checkoutForm}
          </CheckoutFormContext.Provider>
        </Flex>

        <Box flex={1}>
          <OrderSummary
            order={orderSummary!}
            store={fulfillment === "pickup" ? store : undefined}
            headingText={t("components.blocks.CheckoutBlock.Summary")}
            orderSummaryDisplayType={config.orderSummaryDisplayType}
          />
        </Box>
      </Stack>
    </Wrapper>
  )
}

export default CheckoutBlock
