import {
  BoxProps,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react"
import React, { useCallback, useMemo, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useTranslation } from "react-i18next"
import { useCart } from "~/hooks/useCart"
import { usePrintService } from "~/hooks/usePrintService"
import { ReactComponent as BackgroundSvg } from "./assets/couponCodeInputBgMcDonald.svg"
import { ReactComponent as MobileBackgroundSvg } from "./assets/couponCodeInputBgMcDonaldMobile.svg"

interface Props extends BoxProps {}

const CouponCodeInputMcDonald: React.FC<Props> = ({ ...props }) => {
  const backgroundImage = useMemo(
    () => btoa(ReactDOMServer.renderToString(<BackgroundSvg />)),
    []
  )

  const backgroundImageMobile = useMemo(
    () => btoa(ReactDOMServer.renderToString(<MobileBackgroundSvg />)),
    []
  )

  const { t } = useTranslation()
  const { cart, cartActions } = useCart()
  const { printService } = usePrintService(cart.printServiceId)

  const [couponInput, setCouponInput] = useState<string>(cart.couponCode)

  const couponIsLoading = cart.couponCodeStatus === "loading"
  const couponIsValid = cart.couponCodeStatus === "accepted"
  const couponIsInvalid = cart.couponCodeStatus === "rejected"

  const onCouponApply = useCallback(() => {
    cartActions.setCoupon(couponInput)
  }, [couponInput, cartActions])

  const onCouponChange = useCallback(() => {
    setCouponInput("")
    cartActions.setCoupon("") // Apply an empty coupon to reset
  }, [setCouponInput, cartActions])

  const isCouponAvailable = printService && !!printService.hasCoupon
  if (!isCouponAvailable) {
    return null
  }

  return (
    <Flex
      {...props}
      w="full"
      backgroundImage={{
        base: `url(data:image/svg+xml;base64,${backgroundImageMobile})`,
        md: `url(data:image/svg+xml;base64,${backgroundImage})`,
      }}
      backgroundPosition={{ base: "70% 50%", lg: "center" }}
      backgroundColor="#da291c"
      borderRadius="lg"
      flexDirection="column"
      px={{ base: 4, xl: 8 }}
      py={{ base: 4, xl: 6 }}
    >
      <Flex
        flex={1}
        flexDirection={{ base: "column", sm: "row", md: "column", lg: "row" }}
      >
        <Flex alignItems="center" flex={{ base: 1, md: 2, xl: 1 }}>
          <Text
            color="white"
            fontSize="md"
            fontWeight="bold"
            pr={2}
            mb={{ base: 2, sm: 0, md: 2, lg: 0 }}
          >
            {t("components.checkout.fields.CouponCodeInput.Title")}
          </Text>
        </Flex>
        <Flex
          flex={{ base: 1, md: 3, xl: 1 }}
          position="relative"
          alignItems="center"
        >
          <InputGroup>
            <Input
              borderColor="gray.200"
              backgroundColor="white"
              id="p-checkout-coupon"
              data-testid="p-checkout-coupon"
              name="couponCode"
              placeholder={t(
                "components.checkout.fields.CouponCodeInput.Input"
              )}
              autoComplete="off"
              value={couponInput}
              onChange={e => setCouponInput(e.target.value)}
              disabled={couponIsValid}
              _disabled={{ backgroundColor: "gray.200" }}
              isInvalid={couponIsInvalid}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  onCouponApply()
                }
              }}
            />

            <InputRightElement
              padding={0}
              width="auto"
              children={
                couponIsValid ? (
                  <Button
                    id="p-customer-remove-coupon" // GTM: remove coupon
                    colorScheme="gray"
                    variant="link"
                    width="5.5rem"
                    onClick={onCouponChange}
                  >
                    {t("components.checkout.fields.CouponCodeInput.Change")}
                  </Button>
                ) : (
                  <Button
                    id="p-customer-apply-coupon"
                    colorScheme="primary"
                    variant="link"
                    width="5.5rem"
                    onClick={onCouponApply}
                    isLoading={couponIsLoading}
                  >
                    {t("components.checkout.fields.CouponCodeInput.Apply")}
                  </Button>
                )
              }
            />
          </InputGroup>
        </Flex>
      </Flex>
      <Flex alignItems="center" flex={1}>
        <Flex flex={1} />
        <Flex flex={1}>
          {couponIsValid && (
            <Text fontSize="sm" color="white">
              {t("components.checkout.fields.CouponCodeInput.IsValid")}
            </Text>
          )}

          {couponIsInvalid && (
            <Text fontSize="sm" color="white">
              {t("components.checkout.fields.CouponCodeInput.Invalid")}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CouponCodeInputMcDonald
