import { Box, Button, Flex, Icon, Image, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { ImRadioChecked, ImRadioUnchecked } from "react-icons/im"
import ErrorPanel from "~/components/ErrorPanel"
import { useAllPageUploadsAreReady } from "~/hooks/useAllPageUploadsAreReady"
import { usePageCartClientPrintService } from "~/hooks/usePageCartClientPrintService"
import FormHeading from "../FormHeading"
import StripeOption from "./stripe/StripeOption"
export const paymentDataKey = "payment.data"

export interface Props {
  isActive: boolean
  errorMessage: string
}

const PaymentSelector: React.FC<Props> = ({ isActive, errorMessage }) => {
  const { t } = useTranslation()
  const {
    clientPrintService: {
      paymentConfig: { stripe: options },
    },
  } = usePageCartClientPrintService()
  const isReady = useAllPageUploadsAreReady()

  const { displayName, image } = options

  return (
    <Box w="100%" marginTop={8}>
      <FormHeading orderNumber="2">
        {t("components.checkout.payment.PaymentOptionsList.Title")}
      </FormHeading>

      <VStack spacing={0} mt={0} w="100%" borderRadius="md" position="relative">
        <Flex justifyContent="center" alignItems="center" w="100%">
          <Button
            id="click-payment-button"
            type={isActive ? "button" : "submit"}
            m={0}
            py={3}
            pl={1}
            w="100%"
            h="auto"
            variant="ghost"
            boxSizing="content-box"
            isDisabled={!isReady}
            _active={{ backgroundColor: "transparent" }}
            _focus={{ backgroundColor: "transparent" }}
            _hover={{ backgroundColor: "transparent" }}
          >
            <VStack h="100%" w="100%" spacing={2}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                height={30}
                w="100%"
              >
                <Flex justifyContent="start" alignItems="center">
                  {isActive ? (
                    <Icon as={ImRadioChecked} color="primary.500" />
                  ) : (
                    <Icon as={ImRadioUnchecked} />
                  )}

                  <Text ml={2}>
                    {displayName ??
                      t(
                        "components.checkout.payment.PaymentSelector.CreditCard"
                      )}
                  </Text>
                </Flex>

                <Flex justifyContent="flex-end" alignItems="center" h="100%">
                  <Image src={image} maxH="100%" />
                </Flex>
              </Flex>

              <StripeOption />
            </VStack>
          </Button>
        </Flex>
        {errorMessage && (
          <Box
            borderWidth={3}
            borderColor="danger"
            borderStyle="solid"
            position="absolute"
            pointerEvents="none"
            borderRadius={8}
            left={0}
            top={0}
            w="100%"
            h="100%"
          />
        )}
      </VStack>
      {errorMessage && <ErrorPanel error={errorMessage} h={12} mb={0} mt={3} />}
    </Box>
  )
}

export default PaymentSelector
