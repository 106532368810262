import { usePageSession } from "./usePageSession"
import { useUploadEntities } from "./useUploadEntities"

export const useAllPageUploadsAreReady = () => {
  const { pageSession } = usePageSession()
  const { uploads } = useUploadEntities(pageSession.uploadIds)
  // TODO
  // null check required until #1812 is deployed
  return (
    uploads.filter(upload => upload && upload.status !== "ready").length === 0
  )
}
